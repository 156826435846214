import * as React from 'react'
import {Link as ReactLink} from 'react-router-dom'
import {
  Box, Flex,
  Grid,
  GridItem, HStack,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import {Icon} from '@chakra-ui/icons'
import {
  AiOutlineMail,
  AiOutlineMobile
} from 'react-icons/ai'
import {BsFacebook, BsInstagram, BsLinkedin} from 'react-icons/bs'
import {ImXing2} from "react-icons/im";


export interface FooterProps {
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const linkColor = useColorModeValue('gray.700', 'gray.400')
  const companyLabelColor = useColorModeValue('gray.600', 'gray.50')
  return (<>
      <Box as={'footer'}
           display={'flex'}
           alignItems={'center'}
           minH={'15vh'}
           width={'100%'}
           fontSize={'sm'}>

        <VStack m={'0 auto'} width={{base: '90%', md: '80%', xl: '40%'}}>
          <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
            <Text w="100%" fontWeight={'semibold'} color={companyLabelColor}>&copy; breezy bytes
              GmbH {new Date().getFullYear()}</Text>
            <HStack>
              <Link color={linkColor} marginLeft={"0.5rem"}
                    href={"https://www.linkedin.com/company/breezy-bytes-gmbh"} title={"LinkedIn"}
                    target={"_blank"} rel={"me"}><Icon boxSize={"1.5rem"} as={BsLinkedin}/></Link>
              <Link color={linkColor} marginLeft={"0.5rem"} href={"https://www.xing.com/pages/breezy-bytes-gmbh"}
                    title={"Xing"}
                    target={"_blank"} rel={"me"}><Icon boxSize={"1.5rem"} as={ImXing2}/></Link>
              <Link color={linkColor} marginLeft={"0.5rem"} href={"https://www.instagram.com/breezy.bytes"}
                    title={"Instagram"}
                    target={"_blank"} rel={"me"}><Icon boxSize={"1.5rem"} as={BsInstagram}/></Link>
              <Link color={linkColor} marginLeft={"0.5rem"} href={"https://www.facebook.com/breezybytes"}
                    title={"Facebook"}
                    target={"_blank"} rel={"me"}><Icon boxSize={"1.5rem"} as={BsFacebook}/></Link>
            </HStack>
          </Flex>
          <SimpleGrid columns={2}
                      mt={'1em'}
                      m={'0 auto'}
                      w={'100%'}
          >
            <Box textAlign={'left'}>
              <List spacing={1}>
                <ListItem><Link as={ReactLink} color={linkColor} to={'/impressum'}>Impressum</Link></ListItem>
                <ListItem><Link as={ReactLink} color={linkColor} to={'/datenschutz'}>Datenschutz</Link></ListItem>
              </List>
            </Box>
            <Box textAlign={'right'}>
              <Grid gridTemplateColumns={'repeat(2, max-content)'} gap={1}
                    float={'right'}>
                <GridItem>
                  <Link color={linkColor} href={'mailto:kontakt@breezybytes.de'}>kontakt@breezybytes.de</Link>
                </GridItem>
                <GridItem>
                  <Icon as={AiOutlineMail}/>
                </GridItem>
                <GridItem>
                  <Link color={linkColor} href={'tel:+4951519147930'}>+49 (0) 5151 9147930</Link>
                </GridItem>
                <GridItem>
                  <Icon as={AiOutlineMobile}/>
                </GridItem>
              </Grid>
            </Box>
          </SimpleGrid>
        </VStack>

      </Box></>
  )
}

export default Footer
