import React from 'react'
import { Box } from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'

interface Props {
  width : Token<CSS.Property.Width | number, "sizes">,
  height : Token<CSS.Property.Height | number, "sizes">,
  animated? : boolean,
}

const BreezyLogo: React.FC<Props> = ({width, height, animated = false}) => {
  return (
    <Box
      display={'inline-block'}
      as="svg"
      role="img"
      width={width}
      height={height}
      // width="272px"
      // height="153px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1170 605"
      // fill="#1f2127"
      _hover={{
        fill: 'brand.accent',
      }}
    >
      <title>breezy</title>
      <desc>"breezy"-Schriftzug</desc>
      <g transform="matrix(1,0,0,1,-355.061,-559.584)">
        <path className={ animated ? "letter letter-1": "letter"}
              d="M395.635,570.187C395.635,570.187 386.328,636.027 381.033,700.725C375.684,766.071 371.508,831.51 366.297,896.868C366.208,897.985 371.546,877.706 374.117,872.765C386.352,849.251 402.483,825.829 427.048,813.848C433.634,810.635 440.637,808.21 447.752,806.454C454.867,804.697 462.195,803.611 469.519,803.363C508.263,802.048 557.49,811.437 574.652,851.36C578.26,859.752 580.335,871.375 579.822,880.498C579.474,886.696 578.758,892.966 576.914,898.893C559.312,955.482 492.148,973.828 441.098,973.85C415.734,973.861 365.061,966.12 365.061,966.12"/>
      </g>
      <g transform="matrix(1,0,0,1,-378.602,-565.016)">
        <path className={ animated ? "letter letter-2": "letter"}
              d="M654.158,975.315C654.158,975.315 650.376,874.614 668.872,837.623C674.503,826.36 711.868,815.613 726.002,814.348C743.967,812.74 756.006,816.231 756.006,816.231"/>
      </g>
      <g transform="matrix(1.18092,0,0,1,-569.897,-565.609)">
        <path className={ animated ? "letter letter-3": "letter"}
              d="M833.137,925.119C834.452,927.089 860.858,897.431 864.594,893.694C879.305,878.983 913.603,817.343 876.231,808C821.102,794.217 816.067,950.564 859.304,979.389C903.307,1008.72 961.861,900.016 961.861,900.016"/>
      </g>
      <g transform="matrix(1.18092,0,0,1,-373.239,-561.587)">
        <path className={ animated ? "letter letter-4": "letter"}
              d="M833.137,925.119C834.452,927.089 860.858,897.431 864.594,893.694C879.305,878.983 913.603,817.343 876.231,808C821.102,794.217 816.067,950.564 859.304,979.389C903.307,1008.72 961.861,900.016 961.861,900.016"/>
      </g>
      <g transform="matrix(1,0,0,0.902212,-395.238,-481.631)">
        <path className={ animated ? "letter letter-5": "letter"}
              d="M1198.33,802.474C1198.33,802.474 1219.64,799.283 1228.33,799.283C1245.5,799.283 1313.17,785.912 1305.94,822.043C1303.23,835.626 1289.66,847.361 1281.43,857.642C1257.52,887.538 1231.62,916.664 1208.48,947.516C1201.58,956.719 1176.27,986.38 1182.81,999.455C1187.12,1008.07 1215.81,1000.9 1223.07,1000.04C1275.35,993.811 1333.37,995.37 1333.37,995.37"/>
      </g>
      <g transform="matrix(0.894429,0,0,0.864437,-176.996,-438.247)">
        <path className={ animated ? "letter letter-6": "letter"}
              d="M1302.41,790.68C1302.41,790.68 1299.19,803.88 1293.69,847.862C1288.17,892.077 1276.16,989.921 1335.99,999.892C1393.16,1009.42 1428.95,915.849 1444.58,874.153C1446.54,868.93 1476.85,798.526 1489.16,816.999C1502.44,836.91 1486.12,889.772 1482.31,910.732C1470.12,977.747 1395.48,1194.56 1395.48,1194.56"/>
      </g>
    </Box>
  )
}

export default BreezyLogo
