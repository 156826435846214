import * as React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router'


const ScrollToTop: React.FC<any> = ({children}) => {

  const location = useLocation()

  useEffect(() => {
    //overflow-scrolling in App.tsx => therefore we need to scroll to the top using the contentWrapper
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return children

}

export default ScrollToTop
