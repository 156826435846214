import React, {
  MutableRefObject,
  useContext
} from 'react'
import {
  Box,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import MainMenu from './components/MainMenu'
import { Outlet } from 'react-router-dom'
import Footer from './components/Footer'
import { BreezyApplicationContext } from './BreezyApplicationContext'


interface SiteProps {
  navigationRef: MutableRefObject<HTMLDivElement>,
  scrollMargin: number
}

/*
interface EnvironmentConfiguration {
  displayNothing: boolean,
  showColorModeSwitch: boolean
}

const DEFAULT_CONFIGURATION: EnvironmentConfiguration = {
  displayNothing: false,
  showColorModeSwitch: false,
}

const configs: { [key: string]: EnvironmentConfiguration } = {
  'test.breezybytes.de': {...DEFAULT_CONFIGURATION, showColorModeSwitch: true},
  'breezybytes.de': {...DEFAULT_CONFIGURATION, displayNothing: true, showColorModeSwitch: true},
  'localhost': {...DEFAULT_CONFIGURATION, showColorModeSwitch: false},
}

const getConfig = (): EnvironmentConfiguration => {
  const {hostname} = window.location
  const config = configs[hostname]
  if (config === undefined) {

    // WLAN Access with second device ;-)
    if (hostname.startsWith('192.168.1') || hostname
      .startsWith('172.')) {
      return configs['localhost']
    }

    throw new Error(`Unknown config name (${hostname})`)
  }
  return config
}*/

const Site = (props: SiteProps) => {
  const context = useContext(BreezyApplicationContext)
  const backgroundColor = useColorModeValue('breezy.600', 'breezy.700')
  const contentHeight = useBreakpointValue({base: '94vh', xl: '93vh'})

  const config = context.getConfig()
  if (config.displayNothing) {
    return <></>
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name={'theme-color'} content={backgroundColor}/>
      </Helmet>
      <Box>
        <MainMenu refContainer={props.navigationRef}/>
        <Box as={'main'}
             id={'contentWrapper'}
             minH={contentHeight}
             top={'0'}
             position={'relative'}
        >
          <Outlet/>
        </Box>
        <Footer/>
      </Box></>)
}

export default Site
