import * as React from "react";
import {Heading, Box, Text } from "@chakra-ui/react";
import {Helmet} from "react-helmet-async";

interface ImpressumProps {
}

const Impressum: React.FC<ImpressumProps> = (props: ImpressumProps) => {
  //let backgroundColor = useColorModeValue('gray.50', 'gray.800');
  return (<>
      <Helmet>
        <title>Impressum - breezy bytes GmbH</title>
        <meta name={"description"} content={"Impressum der breezy bytes GmbH"}/>
        <meta name={"keywords"} content={"Impressum, Kontaktdaten"}/>
      </Helmet>
      <Box
        w={{base: "100%", lg: "90%", xl: "80%"}}
        m="0 auto"
        px={5}
        mb={3}
        mt={3}
      >
        <Heading as={"h1"} mb={"inherit"}>Impressum</Heading>

        <Heading as={"h2"} fontSize={"lg"}>Angaben gem&auml;&szlig; &sect; 5 TMG</Heading>
        <Text mb={"inherit"}>breezy bytes GmbH<br />
          Gretchenbrink 9<br />
          31789 Hameln</Text>

        <Text mb={"inherit"}>Handelsregister: HRB 222989<br />
          Registergericht: Amtsgericht Hannover</Text>

        <Text mb={"inherit"}><strong>Vertreten durch:</strong><br />
          Benjamin Hintz<br />
          Henning Friedrich</Text>

        <Heading as={"h2"} fontSize={"lg"}>Kontakt</Heading>
        <Text mb={"inherit"}>Telefon: +495151 9147930<br />
          E-Mail: kontakt@breezybytes.de</Text>

        <Heading as={"h2"} fontSize={"lg"}>Umsatzsteuer-ID</Heading>
        <Text mb={"inherit"}>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE348408237</Text>

        <Heading as={"h2"} fontSize={"lg"}>Gewerbeanmeldung</Heading>
        <Text mb={"inherit"}>Die Gewerbeanmeldung nach &sect; 34c GewO wurde am 31.03.2022 durch das Ordnungsamt Hameln erteilt.</Text>

        <Heading as={"h2"} fontSize={"lg"}>Redaktionell verantwortlich</Heading>
        <Text mb={"inherit"}>breezy bytes GmbH<br />
          Gretchenbrink 9<br />
          31789 Hameln</Text>

        <Heading as={"h2"} fontSize={"lg"}>EU-Streitschlichtung</Heading>
        <Text mb={"inherit"}>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</Text>

        <Heading as={"h2"} fontSize={"lg"}>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Heading>
        <Text mb={"inherit"}>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</Text>

        <Text mb={"inherit"}>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></Text>
      </Box>
    </>
  );
}

export default Impressum;
