import * as React from 'react'
import {
  createContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Box,
  BoxProps
} from '@chakra-ui/react'


interface ICardContext {
  rotateStack: () => void
  cardOrder: string[]
  movingCard: string | null
  animationDurationInMillis: number
  offsetCount : number,
}

const initialContext: ICardContext = {
  rotateStack: () => {
    console.error('Method not allowed!')
  },
  cardOrder: [],
  movingCard: null,
  animationDurationInMillis: -1,
  offsetCount: -1,
}

export const StackContext = createContext<ICardContext>(initialContext)

export const CardStack: React.FC<{ cards: string[], offsetCount : number } & BoxProps> = (props) => {
  const movingCardTimer = useRef<NodeJS.Timeout | null>(null)
  const orderCardTimer = useRef<NodeJS.Timeout | null>(null)
  const [movingCard, setMovingCard] = useState<string | null>(null)
  const [cardOrder, setCardOrder] = useState(props.cards)
  const offsetCount = props.offsetCount
  const animationDurationInMillis = 250

  const rotateStack = () => {
    const newCardOrder = rotateArray(cardOrder)
    const newMovingCard = newCardOrder[0]
    setMovingCard(newMovingCard)
    orderCardTimer.current = setTimeout(() => setCardOrder(newCardOrder), animationDurationInMillis * 0.4)
    movingCardTimer.current = setTimeout(() => setMovingCard(null), animationDurationInMillis)
  }

  useEffect(() => {
    return () => {
      clearTimer(movingCardTimer)
      clearTimer(orderCardTimer)
    }
  }, [])

  return <Box display={'block'}
              position={'relative'}
              {...props}>
    <StackContext.Provider value={{rotateStack, cardOrder, movingCard, animationDurationInMillis, offsetCount}}>
      {props.children}
    </StackContext.Provider>
  </Box>
}

const rotateArray = (cardOrder: string[]): string[] => [...cardOrder.slice(-1, cardOrder.length), ...cardOrder.slice(0, -1)]

const clearTimer = (timer: React.MutableRefObject<NodeJS.Timeout | null>) => {
  if (timer !== null) {
    // @ts-ignore
    clearTimeout(timer.current)
  }
}
