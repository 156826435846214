import * as React from 'react'
import {
  MutableRefObject,
  useContext
} from 'react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Link,
  ResponsiveValue,
  Spacer,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { ColorModeSwitcher } from '../ColorModeSwitcher'
import { Link as ReactLink } from 'react-router-dom'
import { HamburgerIcon } from '@chakra-ui/icons'
import * as CSS from 'csstype'
import { genericHashLink } from 'react-router-hash-link'
import BreezyLogo from '../BreezyLogo'
import { BreezyApplicationContext } from '../BreezyApplicationContext'


interface MenuProps {
  refContainer: MutableRefObject<HTMLDivElement>;
}

interface MenuLink {
  label: string
  link: string
  hash?: string
}

const siteMap: MenuLink[] = [
  {label: 'Home', link: '/#home',},
  {label: 'Über uns', link: '/#ueber-uns', hash: '#ueber-uns'},
  {label: 'Karriere', link: '/#Karriere'},
  {label: 'Kontakt', link: '/#kontakt'},
]

const MainMenu: React.FC<MenuProps> = (props: MenuProps) => {
  const context = useContext(BreezyApplicationContext)
  const backgroundColor = useColorModeValue('gray.50', 'gray.900')
  const bytesColor = useColorModeValue('bb.700', 'bb.500')
  const visibilityMenu: ResponsiveValue<CSS.Property.Display> | undefined = useBreakpointValue({
    base: 'none',
    md: 'inline'
  })
  const visibilityButton: ResponsiveValue<CSS.Property.Display> | undefined = useBreakpointValue({
    base: 'inline',
    md: 'none'
  })
  const {isOpen, onOpen, onClose} = useDisclosure()
  const MyHashLink = genericHashLink(Link)
  const scroll = (el: HTMLElement) => {
    el.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  const {showColorModeSwitch} = context.getConfig()
  return (
    <Box as="nav"
         margin={'0 auto'}
         position={'sticky'}
         top={0}
         zIndex={20}
         display={'block'}
         bg={backgroundColor}>

      <Flex alignItems={'center'}
            maxWidth={{base: '100vw', xl: '67vw'}}
            ref={props.refContainer!}
            minH={{base: '6vh', xl: '7vh'}}
            p={0}
            margin={'0 auto'}
            overflow={'hidden'}>
        <Box mx={3}>
          <VStack spacing={0} textAlign={'center'}>
            <Heading
              display={'block'}
              as={'h1'}
              w={'auto'}
              fontSize={{base: '1.6em', xl: '2em'}}
              color={bytesColor}
              letterSpacing={'-0.1em'}>
              <MyHashLink as={ReactLink} to={'./#home'}
                          scroll={scroll}
                          p={0}
                          m={0}
                          _hover={{}}>
                <Flex alignItems={'center'}>
                  <BreezyLogo animated={false} width={'2.5em'} height={'auto'}/> <span className={'bytes'}>bytes</span>
                </Flex>
              </MyHashLink>
            </Heading>
          </VStack>
        </Box>
        <Spacer/>
        <Box m={'0 auto'}
             p={{base: 2, xl: 0}}
             display={visibilityMenu}
        >
          {siteMap.map((menuItem, index) =>
            <MyHashLink variant={'navigation'}
                        smooth
                        as={ReactLink}
                        key={index}
                        to={menuItem.link}
                        scroll={scroll}
            >{menuItem.label}</MyHashLink>
          )}
        </Box>
        <IconButton
          display={visibilityButton}
          aria-label="menu"
          onClick={onOpen}
          size={'sm'}
          mr={2}
          icon={<HamburgerIcon/>}
        />
        {showColorModeSwitch ? <ColorModeSwitcher justifySelf="flex-end"/> : <></> }
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement={'right'}
        onClose={onClose}
        size={'sm'}
      >
        <DrawerOverlay/>
        <DrawerContent>
          <DrawerCloseButton/>
          <DrawerHeader>Menü</DrawerHeader>
          <DrawerBody background={backgroundColor}>
            {siteMap.map((menuItem, index) =>
              <MyHashLink variant={'navigation'}
                          display={'block'}
                          onClick={onClose}
                          smooth
                          as={ReactLink}
                          key={index}
                          to={menuItem.link}
                          scroll={scroll}
              >{menuItem.label}</MyHashLink>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default MainMenu
