import * as React from "react";
import {chakra, HTMLChakraProps} from '@chakra-ui/react'

interface Props {
    clickable?: boolean
}

const filter = (props : HTMLChakraProps<"div"> & Props): object => {
    const propsCopy: HTMLChakraProps<"div"> & Props = {}
    for (const propsKey in props) {
        if (propsKey === "clickable") {
            continue
        }
        if (!props.hasOwnProperty(propsKey)) {
            continue
        }
        // @ts-ignore TODO FIX ts-ignore
        propsCopy[propsKey] = props[propsKey]
    }
    return propsCopy
}

const CardContainer: React.FC<HTMLChakraProps<"div"> & Props> = (props: HTMLChakraProps<"div"> & Props) => {

    const hover = props.clickable ? {boxShadow: "lg"} : {}
    const filteredProps = filter(props);
    return (
        <chakra.div
            {...filteredProps}
            overflow={"hidden"}
            _hover={hover}
        />
    );
}

export default CardContainer;
