import React, { PropsWithChildren } from 'react'


interface EnvironmentConfiguration {
  displayNothing: boolean,
  showColorModeSwitch: boolean
}

const DEFAULT_CONFIGURATION: EnvironmentConfiguration = {
  displayNothing: false,
  showColorModeSwitch: false,
}

const configs: { [key: string]: EnvironmentConfiguration } = {
  'test.breezybytes.de': {...DEFAULT_CONFIGURATION, showColorModeSwitch: true},
  'breezybytes.de': DEFAULT_CONFIGURATION,
  'localhost': {...DEFAULT_CONFIGURATION, showColorModeSwitch: true},
}

const getConfig = (): EnvironmentConfiguration => {
  const {hostname} = window.location
  const config = configs[hostname]
  if (config === undefined) {

    // WLAN Access with second device ;-)
    if (hostname.startsWith('192.168.1') || hostname
      .startsWith('172.')) {
      return configs['localhost']
    }

    throw new Error(`Unknown config name (${hostname})`)
  }
  return config
}

interface ApplicationContext {
  getConfig(): EnvironmentConfiguration,
}

const InitialApplicationContext: ApplicationContext = {getConfig}

export const BreezyApplicationContextProvider: React.FC = (props: PropsWithChildren<any>) =>
  <BreezyApplicationContext.Provider value={InitialApplicationContext}>
    {props.children}
  </BreezyApplicationContext.Provider>

export const BreezyApplicationContext = React.createContext<ApplicationContext>(InitialApplicationContext)
