import * as React from "react";
import {Heading, HeadingProps, forwardRef, useColorModeValue} from "@chakra-ui/react";

/**
 * Default Card Title
 */
const CardTitle = forwardRef<HeadingProps, "div">((props: HeadingProps, ref) => {


    let fontColor = useColorModeValue('gray.700', 'gray.300');

    return (<Heading as={"h2"}
             color={fontColor}
             fontWeight={"semibold"}
             fontSize="lg"
             ref={ref}
             {...props} >
        {props.children}
    </Heading>);
})

export default CardTitle;
