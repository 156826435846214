import * as React from 'react'
import { useContext } from 'react'
import {
  Box,
  BoxProps,
  useColorModeValue
} from '@chakra-ui/react'
import { StackContext } from './CardStack'


export interface CardProps {
  name: string
}

export const Card: React.FC<CardProps & BoxProps> = (props) => {
  const context = useContext(StackContext)
  const moving = context.movingCard === props.name
  const order = context.cardOrder.indexOf(props.name)
  const stackLength = context.cardOrder.length - 1

  const height = 33
  const topWithShuffle = moving ? -height : order
  const colorFactor = 10
  const numberOfCardsInStack = stackLength + 1
  const color1 = useColorModeValue(240, 15)
  const color2 = useColorModeValue(
    (255 - numberOfCardsInStack * colorFactor) + order * colorFactor,
    numberOfCardsInStack * colorFactor + order * colorFactor
  )
  const scale = 100 - (stackLength - order - 1)
  return <Box
    background={`linear-gradient(0deg, rgb(${color1}, ${color1}, ${color1}) 0%, rgb(${color2},${color2},${color2}) 100%)`}
    onClick={() => context.rotateStack()}
    {...props}
    w={`100%`}
    h={`100%`}
    display={'block'}
    position={'absolute'}
    top={`${(context.offsetCount) + -stackLength + topWithShuffle}rem`}
    borderRadius={'1.5rem'}
    padding={'2rem'}
    overflow={'hidden'}
    transition={`all ${context.animationDurationInMillis}ms ease-in-out`}
    zIndex={10 + order}
    style={{scale: `${scale}%`}}
    cursor={'pointer'}
    boxShadow={'0 0 0.025em rgba(0,0,0,0.5)'}
  >
    {props.children}
  </Box>
}
