import React from 'react'
import CardTitle from '../components/Card/CardTitle'
import {
  Avatar,
  Flex,
  HStack,
  Link,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import {
  FaLinkedin,
  FaXing
} from 'react-icons/fa'
import Card from '../components/Card/CardContainer'


interface Props {
  name: string
  position: string
  text: string
  xingId: string
  linkedInId: string
  tags: string[]
  image: string
}

const ProfileNeu: React.FC<Props> = ({name, position, text, xingId, linkedInId, tags, image}) => {
  const tagSize = useBreakpointValue({base: 'sm', lg: 'md'})
  const iconColor = useColorModeValue('bg.400', 'gray.400')
  const tagBackground = useColorModeValue('white', 'gray.800')
  return (
    <Card p={{base: 3, xl: 6}} textAlign={'center'}>

      <Flex flexWrap={'wrap'} alignItems={'center'}>
        <VStack w={{base:"100%", md:'auto'}}>

          <Avatar boxSize={'12em'} name={name} src={image}
                  aria-label={name}/>
          <CardTitle as={'h3'} fontSize={{base: 'lg', lg: 'xl'}}>{name}</CardTitle>
          <HStack>
            <Text fontSize={'sm'}>{position}</Text>
            <HStack>
              <Link href={`https://www.xing.com/profile/${xingId}`} isExternal>
                <Icon boxSize="1.5em" as={FaXing} color={iconColor}/>
              </Link>
              <Link href={`https://www.linkedin.com/in/${linkedInId}/`} isExternal>
                <Icon boxSize="1.5em" as={FaLinkedin} color={iconColor}/>
              </Link>
            </HStack>
          </HStack>
        </VStack>
        <VStack flex={'1'} >
          <Text px={{base: 2, 'lg': 5}} fontSize={{base: 'md', '2xl': 'lg'}}>{text}</Text>
          <Flex flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
            {tags.map((tag, i) => <Tag m={1} key={i} size={tagSize} bg={tagBackground}>{tag}</Tag>)}
          </Flex>
        </VStack>
      </Flex>
    </Card>
  )
}

export default ProfileNeu
