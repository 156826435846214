import Main from './routes/Main'
import Impressum from './routes/Impressum'
import Datenschutz from './routes/Datenschutz'
import {
  Route,
  Routes
} from 'react-router-dom'
import NotFound from './routes/NotFound'
import { RoutesProps } from 'react-router'
import Site from './Site'
import * as React from 'react'
import { MutableRefObject } from 'react'


interface RoutesCustomProps {
  navRef: MutableRefObject<HTMLDivElement>,
  navHeight: number,
}

const BreezyRoutes = (props: RoutesProps & RoutesCustomProps): React.ReactElement => {
  return (<Routes>
    <Route path="/" element={<Site navigationRef={props.navRef} scrollMargin={props.navHeight}/>}>
      <Route path="/" element={<Main scrollMargin={props.navHeight}/>}/>
      <Route path="impressum" element={<Impressum/>}/>
      <Route path="datenschutz" element={<Datenschutz/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Route>
  </Routes>)
}

export default BreezyRoutes
