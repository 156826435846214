import {
  extendTheme,
  type ThemeConfig
} from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { Dict } from '@chakra-ui/utils'
import { StyleFunctionProps } from '@chakra-ui/theme-tools/dist/declarations/src/component'
import 'csshake/dist/csshake.css'


const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

/**
 * Custom Theme für die Website
 * Per Default wird der System-Color-Mode genutzt.
 * Die Farbpalette wurde per JS aus https://smart-swatch.netlify.app/#0ab1f1 generiert.
 */
const theme = extendTheme({
  styles: {
    global: (props: { colorMode: string }) => ({
      '.letter': {
        fill: 'none',
        stroke: mode('bb.400', 'bb.100')(props),
        strokeLinecap: 'round',
        strokeWidth: '24px',
        strokeDashoffset: '100%',
      },
      '@keyframes draw_in': {
        from: {
          strokeDashoffset: '100%',
        },
        to: {
          strokeDashoffset: '0%',
        }
      },
      '@keyframes blend_in': {
        from: {
          opacity: '0',
        },
        to: {
          opacity: '1',
        }
      },
      '@keyframes fly_in': {
        from: {
          opacity: '0',
          right: '-250',
        },
        to: {
          opacity: '1',
          right: '0',
        }
      },
      '@keyframes wiggle': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '50%': {
          transform: 'rotate(10deg)'
        },
        '100%': {
          transform: 'rotate(0deg)'
        },
      },
      '.letter.letter-3,.letter.letter-4': {
        'strokeWidth': '20px'
      },
      '.letter.letter-1': {
        animation: 'draw_in 0.5s forwards',
        strokeDasharray: '100%',
      },
      '.letter.letter-2': {
        animation: 'draw_in 0.5s forwards',
        strokeDasharray: '100%',
        animationDelay: '0.5s',
      },
      '.letter.letter-3': {
        animation: 'draw_in 0.5s forwards',
        strokeDasharray: '100%',
        animationDelay: '0.75s',
      },
      '.letter.letter-4': {
        animation: 'draw_in 0.5s forwards',
        strokeDasharray: '100%',
        animationDelay: '1s',
      },
      '.letter.letter-5': {
        animation: 'draw_in 0.5s forwards',
        strokeDasharray: '100%',
        animationDelay: '1.25s',
      },
      '.letter.letter-6': {
        animation: 'draw_in 0.5s forwards',
        'strokeWidth': '26px',
        strokeDasharray: '100%',
        animationDelay: '1.5s',
      },
      '.bytes': {
        'fontFamily': '"JetBrains Mono", monospace',
      },
      '.bytes-animated': {
        animation: 'blend_in 0.5s forwards',
        animationDelay: '1.75s',
        opacity: '0',
      },
      '.slogan': {
        animation: 'fly_in 0.5s forwards',
        animationDelay: '2s',
        opacity: '0',
        position: 'absolute',
        right: '-250px',
        bottom: '10px',
      },
      '.spinner': {
        transitionProperty: 'all',
        transitionDuration: '2.5s',
        transitionTimingFunction: 'ease-in-out',
      },
      '.spinner:hover': {
        transform: 'rotate(360deg)'
      },
      '.rocket': {
        position: 'absolute',
        top: '0',
        left: '42%',
        transitionProperty: 'all',
        transitionTimingFunction: 'ease-in-out',
      },
      '.rocket.rocket-flying': {
        top: '-250%',
        left: '150%',
      },
      '.check': {
        position: 'absolute',
        top: '20%',
        left: '58%',
      },
      '.check.check-wiggle': {
        animation: 'wiggle 2s linear infinite',
      }
    }),
  },
  config,
  fonts: {
    heading: 'Nunito Sans',
    body: 'Nunito Sans',
  },
  colors: {
    breezy:
      {
        50: '#daf9ff',
        100: '#aee9ff',
        200: '#80dafc',
        300: '#50caf9',
        400: '#23bbf6',
        500: '#09a2dc',
        600: '#007eac',
        700: '#005a7c',
        800: '#00374d',
        900: '#00141e',
      },
    bb:
      {
        50: '#e7f8f3',
        100: '#cce6e0',
        200: '#aed3cc',
        300: '#8ec3bb',
        400: '#70b2a9',
        500: '#579891',
        600: '#437673',
        700: '#2f5453',
        800: '#1a3333',
        900: '#011211',
      },
    bg: {
      50: '#f0f5f5',
      100: '#d8dede',
      200: '#bdcaca',
      300: '#a0b5b5',
      400: '#84a0a0',
      500: '#6b8787',
      600: '#536969',
      700: '#3d4a4b',
      800: '#252d2d',
      900: '#0a0f0f',
    }
  },
  components: {
    Heading: {
      baseStyle: (props: Dict | StyleFunctionProps) => ({
        fontWeight: 'semibold',
        color: props.colorMode === 'light' ? 'bb.600' : 'bb.300'
      }),
      variants: {
        'h3': (props: Dict | StyleFunctionProps) => ({
          fontSize: '1.2em',
          mt: 3,
          mb: 1,
        }),
        'h3-gray': (props: Dict | StyleFunctionProps) => ({
          color: props.colorMode === 'light' ? 'bg.800' : 'bb.300',
          fontSize: '1.2em',
          mt: 3,
          mb: 1,
        }),
      }
    },
    Button: {
      variants: {
        'bb-solid': (props: Dict | StyleFunctionProps) => ({
          borderRadius: 'lg',
          border: '1px',
          borderColor: mode('gray.100', 'gray.900')(props),
          background: mode('white', 'gray.700')(props),
          color: mode('gray.800', 'white')(props),
          _hover: {
            textDecoration: 'none',
            background: mode('gray.100', 'gray.600')(props),
            shadow: 'md',
          }
        }),
      },
    },
    Link: {
      variants: {
        'navigation': (props: Dict | StyleFunctionProps) => ({
          //background: mode('gray.200', 'breezy.800')(props),
          color: mode('gray.800', 'white')(props),
          borderRadius: 'none',
          m: 1,
          padding: 2,
          fontSize: 14,
          fontWeight: 'semibold',
          textTransform: 'uppercase',
          _hover: {
            textDecoration: 'none',
            background: mode('gray.100', 'gray.800')(props),
            shadow: 'md',
          },
        }),
        'bb-call-to-action': (props: Dict | StyleFunctionProps) => ({
          w: 'auto',
          display: 'inline-flex',
          wrap: 'nowwrap',
          alignItems: 'center',
          fontWeight: 'semibold',
          p: 2,
          borderRadius: 'lg',
          border: '1px',
          borderColor: mode('bb.400', 'gray.700')(props),
          background: mode('bb.500', 'bb.600')(props),
          color: mode('white', 'white')(props),
          _hover: {
            textDecoration: 'none',
            background: mode('bb.600', 'gray.700')(props),
            shadow: 'md',
          }
        }),
      },
    }
  }
})

export default theme
