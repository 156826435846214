import * as React from 'react'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import { Link as ReactLink } from 'react-router-dom'
import {
  Box,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import CardContainer from '../components/Card/CardContainer'
import { Icon } from '@chakra-ui/icons'
import {
  BsCheck,
  BsGear,
  BsPerson
} from 'react-icons/bs'
import {
  BiMailSend,
  BiRocket,
} from 'react-icons/bi'
import CardTitle from '../components/Card/CardTitle'
import { genericHashLink } from 'react-router-hash-link'
import BreezyLogo from '../BreezyLogo'


interface Props {
  wrapperWidth: string | undefined
}

const rocketAnimationDurationInSeconds = 2
const checkAnimationDurationInSeconds = 2

const clearTimer = (timer: React.MutableRefObject<NodeJS.Timeout | null>) => {
  if (timer !== null) {
    // @ts-ignore
    clearTimeout(timer.current)
  }
}

const WelcomeBanner: React.FC<Props> = (props: Props) => {
  const iconColor = useColorModeValue('gray.700', 'white')
  const bytesColor = useColorModeValue('bb.700', 'bb.500')
  const [rocketMoving, setRocketMoving] = useState(false)
  const [rocketShaking, setRocketShaking] = useState(false)
  const [checkWiggle, setCheckWiggle] = useState(false)
  const rocketMovingTimer = useRef<NodeJS.Timeout | null>(null)
  const rocketShakingTimer = useRef<NodeJS.Timeout | null>(null)
  const checkWiggleTimer = useRef<NodeJS.Timeout | null>(null)

  const rocket = () => {
    setRocketMoving(true)
    setRocketShaking(true)
    const durationInMillis = rocketAnimationDurationInSeconds * 1000
    rocketMovingTimer.current = setTimeout(() => setRocketMoving(false), durationInMillis)
    rocketShakingTimer.current = setTimeout(() => setRocketShaking(false), durationInMillis * 2)
  }


  const check = () => {
    setCheckWiggle(true)
    const durationInMillis = checkAnimationDurationInSeconds * 1000
    checkWiggleTimer.current = setTimeout(() => setCheckWiggle(false), durationInMillis)
  }


  useEffect(() => {
    return () => {
      clearTimer(rocketMovingTimer)
      clearTimer(rocketShakingTimer)
      clearTimer(checkWiggleTimer)
    }
  }, [])

  const MyHashLink = genericHashLink(Link)


  return (
    <>
      <Box textAlign={'center'}>
        <Box
          w={'auto'}
          display={'inline-block'}
          m={'0 auto'}
          mb={{base: '3em', lg: '6em'}}
          position="relative">
          <Heading
            display={'block'}
            w={'auto'}
            fontSize={{base: '3.5em', lg: '5em', xl: '7em'}}
            color={bytesColor}
            letterSpacing={'-0.1em'}
            mb={{base: 1, xl: 3}}>
            <Flex alignItems={'center'}>
              <BreezyLogo animated={true} width={'2.5em'} height={'auto'}/> <span
              className={'bytes bytes-animated'}>bytes</span>
            </Flex>
          </Heading>
          <Text align={'center'}
                as={'span'}
                display={'inline-block'}
                w={'auto'}
                m={'0 auto'}
                fontSize={{base: 'xs', lg: 'sm', xl: 'md'}}
                float={'right'}
                bottom={{base: '-0.5em', lg: '-0.25em', xl: '0.25em'}}
                className={'slogan'}>
            Software neu gedacht
          </Text>
        </Box>

        <SimpleGrid
          columns={[1, null, 3]}
          w={props.wrapperWidth}
          m={'0 auto'}
          spacing={{base: 3, md: 6}}
        >
          <Box p={3}>
            <Box position={'relative'} height={{base: '6.2em', xl: '9.2em'}} my={3}>
              <Box
                className={`shake ${rocketShaking ? 'shake-constant shake-hard' : ''} rocket ${rocketMoving ? 'rocket-flying' : ''}`}
                onMouseEnter={rocket}
                transitionDuration={`${rocketAnimationDurationInSeconds}s`}>
                {/*<Rocket/>*/}
                <Icon boxSize={{base: '5em', xl: '8em'}}
                      as={BiRocket}
                      color={iconColor}
                />
              </Box>
            </Box>
            <CardTitle my={3}>Von der Idee zum Programm</CardTitle>
            <Text>Sie haben eine konkrete Software-Idee oder möchten spezielle Abläufe in Ihrem Unternehmen
              digitalisieren? Mit Ihnen zusammen konzeptionieren und entwickeln wir eine Softwarelösung, maßgeschneidert
              auf Ihre individuellen Anforderungen.</Text>
          </Box>
          <CardContainer p={3}>
            <Box position={'relative'}
                 onMouseEnter={check}>
              <Icon boxSize={{base: '5em', xl: '8em'}}
                    as={BsPerson}
                    color={iconColor}
                    my={3}/>
              <Icon boxSize={{base: '4em'}}
                    as={BsCheck}
                    color={iconColor}
                    className={`check ${checkWiggle ? 'check-wiggle' : ''}`}/>
            </Box>
            <CardTitle my={3}>Sie fragen, wir antworten</CardTitle>
            <Text>Welche Digitalisierungsmaßnahmen passen zu meinem Unternehmen? Wie können wir mit Software wertvolle
              Zeit und Kosten sparen? Wie lassen sich unsere Geschäftsprozesse automatisieren? Wir geben Antworten und
              stehen Ihnen und Ihren Mitarbeitern beratend zur Seite.</Text>
          </CardContainer>
          <CardContainer p={3}>
            <Icon boxSize={{base: '5em', xl: '8em'}} as={BsGear} color={iconColor} my={3} className={'spinner'}/>
            <CardTitle my={3}>Update gefällig?</CardTitle>
            <Text>Wie eine gut geölte Maschine muss auch Software regelmäßig gewartet und modernisiert werden. Wir
              entstauben bestehende Systeme, sorgen für Sicherheit und unterstützen Sie bei der Weiterentwicklung Ihrer
              Software.</Text>
          </CardContainer>
        </SimpleGrid>
        <MyHashLink variant={'bb-call-to-action'}
                    mt={3}
                    p={3}
                    smooth
                    as={ReactLink}
                    to={'/#kontakt'}
                    scroll={(el: HTMLElement) => {
                      el.scrollIntoView({behavior: 'smooth', block: 'start'})
                    }

                    }
        >Neugierig?&nbsp;<Icon
          boxSize="1.5em" as={BiMailSend}/></MyHashLink>
      </Box>
    </>
  )
}

export default WelcomeBanner
