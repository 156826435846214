import * as React from 'react'
import {
  useMemo,
  useRef
} from 'react'
import { ChakraProvider, } from '@chakra-ui/react'
import theme from './theme'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/nunito-sans'
import '@fontsource/jetbrains-mono'
import { HelmetProvider } from 'react-helmet-async'
import ScrollToTop from './routes/ScrollToTop'
import BreezyRoutes from './Routes'
import { BreezyApplicationContextProvider } from './BreezyApplicationContext'
import useWindowSize from './hooks/WindowSize'


export const App = () => {
  const {size} = useWindowSize()
  const navRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement> //override wegen undefined...

  // Memo to react to changes of the Window-size FIXME can be replaced with a supplier-function of the height.
  const getNavHeight = useMemo(() => {
    return navRef.current?.clientHeight ?? 0
    // eslint-disable-next-line
  }, [size])

  return <ChakraProvider theme={theme}>
    <HelmetProvider>
      <BreezyApplicationContextProvider>
        <BrowserRouter>
          <ScrollToTop>
            <BreezyRoutes navRef={navRef} navHeight={getNavHeight}/>
          </ScrollToTop>
        </BrowserRouter>
      </BreezyApplicationContextProvider>
    </HelmetProvider>
  </ChakraProvider>
}
