import {
  Heading,
  SimpleGrid,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import {
  AiOutlineMail,
  AiOutlineMobile
} from 'react-icons/ai'
import * as React from 'react'
import ContactCard from '../components/ContactCard'


interface Props {
  wrapperWidth: string | undefined
}

const KontaktSection: React.FC<Props> = (props: Props) => {

  const iconColor = useColorModeValue('gray.700', 'white')

  return (
    <>
      <VStack textAlign={'center'}
              w={props.wrapperWidth}
              m={'0 auto'}>
        <Heading as={'h2'} w={'100%'} mb={3}>Nehmen Sie Kontakt mit uns auf</Heading>
        <SimpleGrid
          columns={[1, null, 2]}
          spacing={{base: 3, md: 6}}
          w={{base: '100%', lg: '70%', xl: '60%'}}
        >
          <ContactCard
            icon={AiOutlineMail}
            color={iconColor}
            text={'Schreiben Sie uns doch, wir können Ihre E-Mail kaum erwarten.'}
            callToAction={'E-Mail schreiben'}
            action={() => window.location.href = 'mailto:kontakt@breezybytes.de'}/>

          <ContactCard
            icon={AiOutlineMobile}
            color={iconColor}
            text={'Rufen Sie uns gern an. Wir freuen uns auf Ihren Anruf.'}
            callToAction={'Anrufen'}
            action={() => window.location.href = 'tel:+4951519147930'}/>

        </SimpleGrid>
      </VStack>
    </>
  )
}

export default KontaktSection
