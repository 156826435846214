import {
  Box,
  Heading,
  Link,
  Text
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import * as React from 'react'


const NotFound : React.FC<{}> = ()  => (
  <Box>
    <Heading as={'h1'} mb={3}>Ups, da ist etwas schief gelaufen</Heading>
    <Text>Die gewünschte Seite konnte leider nicht gefunden werden.</Text>
    <Link as={ReactLink} color="breezy.500" to="/">Hier geht es zum Anfang...</Link>
  </Box>
)

export default NotFound;
