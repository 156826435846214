import React from 'react'
import { Heading } from '@chakra-ui/react'


const CardHeading: React.FC = ({children}) => {
  return <Heading as={'h3'}
                  variant={'h3-gray'}
                  pb={2}>
    {children}
  </Heading>
}

export default CardHeading
