import {
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import WelcomeBanner from '../sections/WelcomeBanner'
import AboutSection from '../sections/AboutSection'
import KarriereSection from '../sections/KarriereSection'
import KontaktSection from '../sections/KontaktSection'
import SectionWrapper from '../components/SectionWrapper'


export interface WelcomeProps {
  scrollMargin: number,
}

const Main: React.FC<WelcomeProps> = (props: WelcomeProps) => {
  const minHeight = useBreakpointValue({base: '94vh', xl: '93vh'})
  const topPadding = useBreakpointValue({base: '2em', md: '1em', xl: '0'})
  const wrapperWidth = useBreakpointValue({base: '100%', md: '90%', lg: '85%', xl: '80%'})
  const wrapperWidthAbout = useBreakpointValue({base: '100%', md: '80%', lg: '75%', xl: '50%'})

  const sectionBackground = useColorModeValue("bg.50", "gray.900")
  
  return <>
    <Helmet>
      <title>Willkommen bei breezybytes.de</title>
      <meta name={'description'}
            content={'Willkommen bei der breezy bytes GmbH - einem Softwareentwicklungs- und IT-Beratungsunternehmen aus der Rattenfängerstadt Hameln.'}/>
      <meta name={'keywords'}
            content={'Softwareentwicklung, IT-Consulting, Beratung, Software Development, Software Engineering'}/>
    </Helmet>

    <SectionWrapper
      minH={minHeight}
      scrollMarginTop={props.scrollMargin + 'px'}
      id={'home'}
      py={topPadding}
    >
      <WelcomeBanner wrapperWidth={wrapperWidth}/>
    </SectionWrapper>
    <SectionWrapper
      scrollMarginTop={props.scrollMargin + 'px'}
      minH={minHeight}
      alignItems={'center'}
      bg={sectionBackground}
      id={'ueber-uns'}
      py={topPadding}>
      <AboutSection wrapperWidth={wrapperWidthAbout}/>
    </SectionWrapper>
    <SectionWrapper
      minH={minHeight}
      scrollMarginTop={props.scrollMargin + 'px'}
      id={'Karriere'}
      py={topPadding}>
      <KarriereSection wrapperWidth={wrapperWidth} scrollMargin={props.scrollMargin}/>
    </SectionWrapper>
    <SectionWrapper
      minH={minHeight}
      scrollMarginTop={props.scrollMargin + 'px'}
      id={'kontakt'}
      bg={sectionBackground}
      py={topPadding}
    mb={0}>
      <KontaktSection wrapperWidth={wrapperWidth}/>
    </SectionWrapper>
  </>


}

export default Main
