import * as React from "react";
import {chakra, HTMLChakraProps} from '@chakra-ui/react'

const filter = (props : HTMLChakraProps<"div">): object => {
  const propsCopy: HTMLChakraProps<"div"> = {}
  for (const propsKey in props) {
    if (propsKey === "clickable") {
      continue
    }
    if (!props.hasOwnProperty(propsKey)) {
      continue
    }
    // @ts-ignore TODO FIX ts-ignore
    propsCopy[propsKey] = props[propsKey]
  }
  return propsCopy
}

const SectionWrapper: React.FC<HTMLChakraProps<"div">> = (props: HTMLChakraProps<"div">) => {
  const filteredProps = filter(props);
  return (
    <chakra.div
      display={"flex"}
      flexWrap={"wrap"}
      scrollSnapAlign={"start"}
      as={"section"}
      w={"100%"}
      p={0}
      mb={{base: "2em", lg: "4em"}}
      alignItems={'center'}
      {...filteredProps}
    />
  );
}

export default SectionWrapper;
