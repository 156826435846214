import React from 'react'
import CardContainer from './Card/CardContainer'
import { Icon } from '@chakra-ui/icons'
import {
  Button,
  Text
} from '@chakra-ui/react'


interface Props {
  icon: React.Component
  iconColor: string,
  text: string
  callToAction: string
  action: () => void
}

const ContactCard: React.FC<Props & React.ComponentProps<any>> = ({
                                                                    icon, iconColor, text, callToAction, action
                                                                  }) => {
  return (<CardContainer p={3}>
    <Icon boxSize={{base: '6em', xl: '8em'}}
          as={icon}
          color={iconColor}
          my={3}/>
    <Text height={"3em"} >
      {text}
    </Text>
    <Button aria-label={callToAction}
            variant={'bb-solid'}
            mt={3}
            onClick={action}>
      {callToAction}
    </Button>
  </CardContainer>)
}

export default ContactCard
