import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { CgCodeSlash, } from 'react-icons/cg'
import * as React from 'react'
import developerBackground from '../assets/img/adomas-aleno-b31ws2nFhYc-unsplash.jpg'
import internBackground from '../assets/img/nick-morrison-FHnnjk1Yj7Y-unsplash.jpg'
import { CardStack } from '../CardStack'
import { Card } from '../Card'
import CardCover from '../CardCover'
import CardHeading from '../CardHeading'


interface Props {
  scrollMargin: number,
  wrapperWidth: string | undefined,
}

const NerdyList: React.FC<{ items: string[] }> = ({items}) => {
  const iconColor = useColorModeValue('bb.600','bb.300');
  return (
    <List spacing={2}>
      {items.map((it, index) => <ListItem key={index}>
        <Flex wrap={'nowrap'} alignItems={'center'}>
          <ListIcon as={CgCodeSlash} color={iconColor}/>
          <Text>{it}</Text>
        </Flex>
      </ListItem>)}
    </List>)

}

const ApplyNowCard = () =>
  <Card name={'cta'}
        background={useColorModeValue('bb.300', 'bb.700')}
        onClick={(e) => e.preventDefault()}>
    <Center w={'100%'}
            h={'100%'}>
      <Button className={'shake shake-slow shake-constant shake-constant--hover'}
              fontSize={'3xl'}
              variant={'solid'}
              backgroundColor={useColorModeValue('bb.200', 'bb.500')}
              color={useColorModeValue('bb.700', 'bb.300')}
              onClick={(e) => window.location.href = 'mailto:bewerbung@breezybytes.de'}>
        Jetzt bewerben!
      </Button>
    </Center>
  </Card>

const KarriereSection: React.FC<Props> = (props: Props) => {
  const sectionBackground = useColorModeValue('bg.50', 'gray.900')
  const jobCards = ['cta', 'offerings', 'requirements', 'description', 'cover']
  const internshipCards = ['cta', 'details', 'description', 'cover']
  const topOffset = Math.max(jobCards.length, internshipCards.length)
  return <>
    <Grid w={{base: '100%', lg: '90%', xl: '80%'}}
          m={{base: 4, md: '0 auto'}}
          gap={{base: 3, xl: 5}}
          templateRows={{base: 'repeat(3, max-content)', md: 'repeat(2, max-content)', xl: 'repeat(1, auto)'}}
          templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)'}}
          h={'max-content'}
          position={'relative'}
    >
      <GridItem colSpan={{base: 1, md: 2, xl: 1}}
                position={'relative'}
                h={'max-content'}
      >
        <Box bg={{base: sectionBackground}}
             p={{base: 5, lg: 6, '2xl': 7}}
             borderRadius={'1.5rem'}
             position={{base: 'relative', xl: 'absolute'}}
             top={{base: 0, xl: topOffset + 'rem'}}
             h={{base: 'auto', xl: '30rem'}}>
          <Heading as={'h2'}>breezy bytes wants you!</Heading>
          <Text fontSize={{base: 'md', xl: 'lg'}} mt={3}>
            Wir sind stets auf der Suche nach neuen Teammitgliedern. Wenn du Lust auf moderne –breezy–
            Technologien und Teamwork hast, möchten wir dich kennenlernen. Werde Teil unserer Geschichte und bewirb
            dich noch heute per E-Mail bei uns!
          </Text>
        </Box>
      </GridItem>
      <GridItem colSpan={{base: 1, md: 1, xl: 1}}
                position={'relative'}
                height={30 + topOffset + 'rem'}>
        <CardStack cards={jobCards}
                   offsetCount={topOffset}
                   height={'30rem'}>
          <ApplyNowCard/>
          <Card name={'offerings'}>
            <CardHeading>Was wir bieten</CardHeading>
            <NerdyList items={[
              'Spannende Entwicklungsaufgaben in Kunden- und Inhouse-Projekten',
              'Fortbildungen und Tagungen, um immer auf dem aktuellsten Stand zu sein',
              'Remote first: Arbeite von zuhause aus'
            ]}/>
          </Card>
          <Card name={'requirements'}>
            <CardHeading>Was wir erwarten</CardHeading>
            <NerdyList items={[
              'Mehrere Jahre Erfahrung in der Software-Entwicklung',
              'Erfahrung mit Java und idealerweise TypeScript',
              'Einen hohen Qualitätsanspruch – Unittests sind selbstverständlich',
              'Idealerweise Erfahrungen mit Cloud-Technologien',
              'Deutsch- oder englischsprachig',
              'Spaß an der Arbeit im Team und mit Kunden',
            ]}/>
          </Card>
          <Card name={'description'}>
            <Text fontSize={'1.3rem'} lineHeight={'175%'}>
              Du suchst eine neue Herausforderung im Bereich moderner Software-Entwicklung? Das trifft sich gut: Wir
              sind
              auf der Suche nach erfahrenen Softwareentwicklern, die uns bei vielfältigen eigenen und
              Kundenprojekten unterstützen.
            </Text>
          </Card>
          <Card name={'cover'}
                backgroundImage={`linear-gradient(0deg, rgba(47,84,83,1) 0%, rgba(47,84,83,0) 100%), url('${developerBackground}')`}
                backgroundPosition={'center 75%'}
                backgroundSize={'cover'}
          >
            <CardCover>
              full<br/>
              stack<br/>
              dev&shy;el&shy;oper()
            </CardCover>
          </Card>
        </CardStack>
      </GridItem>
      <GridItem colSpan={{base: 1, md: 1, xl: 1}}
                h={'auto'}
                position={'relative'}
                height={30 + topOffset + 'rem'}>
        <CardStack cards={internshipCards}
                   offsetCount={topOffset}
                   height={'30rem'}>
          <ApplyNowCard/>
          <Card name={'details'}>
            <CardHeading>Was wir bieten</CardHeading>
            <NerdyList items={[
              'Konzeption moderner Software-Architekturen',
              'Software-Entwicklung mit Java, Kotlin und TypeScript',
              'Kennenlernen moderner Software-Entwicklungsmethoden',
              'Umgang mit Cloud-Technologien',
              'Spannende Entwicklungsaufgaben in Kunden- und Inhouse-Projekten',
              'Remote first: Arbeite von zuhause aus',
            ]}/>
          </Card>
          <Card name={'description'}>
            <Text fontSize={'1.3rem'} lineHeight={'175%'}>
              Wir bieten technikaffinen Schüler:innen oder Werksstudent:innen die Gelegenheit, bei uns mitzuwirken und
              in den Bereich der Software-Entwicklung reinzuschnuppern.
            </Text>
          </Card>
          <Card name={'cover'}
                backgroundImage={`linear-gradient(0deg, rgba(47,84,83,1) 0%, rgba(47,84,83,0) 100%), url('${internBackground}')`}
                backgroundPosition={'center'}
                backgroundSize={'cover'}
          >
            <CardCover>
              <Text>
                prak&shy;tikant:in()
              </Text>
            </CardCover>
          </Card>
        </CardStack>
      </GridItem>
    </Grid>
  </>
}

export default KarriereSection
