import {
  Divider,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react'
import * as React from 'react'
import ProfileNeu from './ProfileNeu'
import benjamin from '../assets/img/profile-benjamin-hintz.jpg'
import henning from '../assets/img/profile-henning-friedrich.png'


interface Props {
  wrapperWidth: string | undefined
}

const AboutSection: React.FC<Props> = (props: Props) => {
  return (
    <>
      <VStack textAlign={'center'}>
        <Heading as={'h2'}>
          <Text as={'span'}>Hi, wir sind breezy bytes!</Text>
        </Heading>
        <Text px={4}>Während andere noch über Digitalisierung sprechen packen wir es an.</Text>
        <Text px={4}>Wir bieten Ihnen über 25 Jahre berufliche Erfahrung in der Entwicklung von Software.</Text>
        <VStack spacing={'2em'} m="0 auto" w={props.wrapperWidth}>
          <Divider w={'100%'} py={3}/>
          <ProfileNeu
            name={'Benjamin Hintz'}
            position={'Co-Founder, Software Engineer'}
            text={`Schon im Kindesalter entdeckte Benjamin seine Leidenschaft für das Programmieren. Einen
                anderen Berufswunsch als Softwareentwickler gab es daher nie. Bereits im Medieninformatikstudium
                startete er seine Selbstständigkeit und unterstützt seitdem große wie auch kleine Unternehmen mit seiner
                Expertise. Mit modernsten Technologien plant Benjamin umfangreiche Softwarearchitekturen in der Cloud,
                macht aus behäbigen Monolithen agile Module und berät Kunden bei Digitalisierungsprojekten.`}
            xingId={'Benjamin_Hintz2'}
            linkedInId={'benjamin-hintz'}
            tags={['Java', 'Kotlin', 'TypeScript', 'AWS', 'Azure', 'Spring Boot', 'ReactJS']}
            image={benjamin}
          />
          <Divider w={'100%'} py={3}/>
          <ProfileNeu
            name={'Henning Friedrich'}
            position={'Co-Founder, Software Engineer'}
            text={`Henning saß bereits vor 20 Jahren gern am Computer und programmierte Websites – auch
            damals schon mit Benjamin zusammen. In seinem dualen Studium der Wirtschaftsinformatik konnte er endlich
            sein Hobby zum Beruf machen und das nötige Know-How dafür sammeln. Mit modernen Programmiersprachen
            entwickelt er heute smarte Softwarelösungen, die verstaubte Systeme ablösen und verhalf damit besonders
            Kunden aus der Finanzbranche zu mehr Fortschritt.`}
            xingId={'Henning_Friedrich2'}
            linkedInId={'henning-friedrich'}
            tags={['Java', 'TypeScript', 'COBOL', 'AWS', 'Spring Boot', 'ReactJS',  'IBM Mainframe',]}
            image={henning}
          />
        </VStack>
      </VStack>
    </>
  )
}

export default AboutSection
