import React from 'react'
import {
  Box,
  Heading
} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { BsFillArrowDownCircleFill } from 'react-icons/bs'


const CardCover: React.FC = ({children}) => {
  return <Box w={'100%'}
              h={'100%'}>
    <Box w={'100%'}
            h={'95%'}>
      <Heading as={'h2'}
               color={'white'}
               fontSize={{
                 base: '4em',
               }}
               style={{
                 textTransform: 'lowercase',
                 fontFamily: 'JetBrains Mono'
               }}
               textShadow={'0 0.025em 0.05em rgba(0,0,0,0.5)'}
      >
        {children}
      </Heading>
    </Box>
    <Box w={'100%'}
         h={'5%'}
         textAlign={'center'}
         color={'white'}
         opacity={0.75}
         transition={'opacity 0.25s ease-in-out'}
         _hover={{opacity: 1}}>
      <Box fontSize={'small'}
           fontWeight={'semibold'}
           pb={1}>
        mehr erfahren
      </Box>
      <Icon as={BsFillArrowDownCircleFill}/>
    </Box>
  </Box>
}

export default CardCover
